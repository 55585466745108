<template>
    <div class="exam-auditors">
        <div class="general-functions">
            <b-row>
                <b-col cols="12">
                    <b-dropdown
                        v-if="!isNew"
                        text="Hinzufügen"
                        :size="size"
                        variant="outline-primary"
                    >
                        <b-dropdown-item @click="openModal(modals.addNewId)"
                            >Neue Person</b-dropdown-item
                        >
                    </b-dropdown>
                    <b-dropdown
                        v-if="checkRight('write')"
                        class="ml-1"
                        :size="size"
                        :disabled="busyExport.excel"
                        variant="outline-secondary"
                    >
                        <template #button-content>
                            <Spinner
                                v-if="busyExport.excel"
                                small
                                variant="secondary"
                            />
                            Listen generieren
                        </template>

                        <b-dropdown-item
                            v-if="exam.type == 'camebridge'"
                            @click="exportCamebridgeList"
                            >Cambridge-Liste</b-dropdown-item
                        >
                    </b-dropdown>
                    <TableExport
                        v-if="checkRight('write')"
                        class="ml-1 d-inline"
                        :busy="busyExport"
                        :dataAvailable="dataAvailable"
                        :showPDF="false"
                        @exportExcel="exportExcel"
                    />
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col md="4">
                    <CheckboxSelect
                        :size="size"
                        :options="auditorTypes"
                    ></CheckboxSelect>
                </b-col>
            </b-row>
            <b-row class="my-1">
                <b-col>
                    <b-button :size="size" @click="applyFilter"
                        >Filter anwenden</b-button
                    >
                    <b-button
                        class="ml-1"
                        :size="size"
                        @click="resetFilter"
                        variant="danger"
                        >Filter zurücksetzen</b-button
                    >
                </b-col>
            </b-row>
            <b-row class="my-3">
                <b-col md="12">
                    <TableFilter v-model="filter.pattern" />
                </b-col>
            </b-row>
        </div>

        <div class="auditors-filter my-3">
            <b-row> </b-row>
        </div>

        <template v-if="filter.id.length > 0">
            <PersonsList
                :showedFields="selectedFields"
                :fieldDefinition="fields"
                :filter="filter"
                :filterUpdate="filterUpdate"
                :additionalData="examsAuditors"
                :rowClass="rowClass"
            >
                <template #cell(type)="{data}">
                    {{
                        auditorTypes.find(item => item.value == data.value).text
                    }}
                </template>

                <template #actions="{data}">
                    <b-button
                        v-if="data.item.pending == 1 && checkRight('write')"
                        variant="outline-secondary"
                        size="sm"
                        class="mx-1"
                        @click="openModal(modals.confirmId, { ...data.item })"
                    >
                        Änderung zulassen
                    </b-button>
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        class="mx-1"
                        @click="
                            openModificationModal({
                                record: data.item,
                                mode: 'edit'
                            })
                        "
                        >Bearbeiten</b-button
                    >
                    <b-button
                        v-if="checkRight('delete')"
                        variant="outline-danger"
                        size="sm"
                        class="mx-1"
                        @click="openDeleteModal({ record: data.item })"
                        >Zuweisung entfernen</b-button
                    >
                </template>
            </PersonsList>
        </template>
        <template v-else>
            <div class="small text-center">
                Es wurden noch keine Teilnehmenden zugewiesen
            </div>
        </template>

        <EditModal
            :id="modals.addNewId"
            size="xl"
            title="Neue Person(en) hinzufügen"
            :busy="busyAddingPerson"
            @ok="handleModification"
            @hidden="closeModal(modals.addNewId)"
        >
            <b-alert show variant="warning">
                Hier können Personen zu einer Veranstaltung hinzugefügt werden.
                Über die Felder kann eine Person direkt hinzugefügt werden. Über
                den Button "Person suchen" können die eingegeben Informationen
                aber auch genutzt werden um zu überprüfen ob eine Person schon
                in den Kontakten hinterlegt ist. Durch die Auswahl dieser Person
                kann dann der vorhandene Datensatz hinzugefügt werden.
            </b-alert>
            <h5>Stammdaten</h5>
            <PersonsEditView
                :allowedIoFields="allowedPersonsIoFields"
                :busy="busyAddingPerson"
                :data="modificationRecord"
            />
            <hr />
            <h5>Kontaktdaten</h5>
            <PersonContactView
                :busy="busyAddingPerson"
                :data="modificationRecord"
            />
            <hr />
            <h5>Prüfungsinformationen</h5>
            <FormGroupBuilder
                :fields="auditIoFields"
                :data="modificationRecord"
            />
            <hr />
            <div class="d-block text-right">
                <b-button
                    :size="size"
                    :disabled="busyAddingPerson"
                    @click="searchExistingPerson"
                    >Person suchen</b-button
                >
            </div>
            <template v-if="Object.values(existingPersonsFilter).length > 0">
                <hr />
                <PersonsList
                    :selectable="false"
                    :fieldDefinition="fields"
                    :filterUpdate="existingPersonsFilterUpdate"
                    :filter="existingPersonsFilter"
                    :externalFetchMode="true"
                >
                    <template #actions="{data}">
                        <b-button
                            variant="outline-primary"
                            size="sm"
                            class="mx-1"
                            :disabled="busyAddingPerson"
                            @click="useExistingPerson(data)"
                            >Zuweisen</b-button
                        >
                    </template>
                </PersonsList>
            </template>
        </EditModal>

        <EditModal
            :busy="busyPersons.mutation"
            :mode="modificationMode"
            size="xl"
            @ok="handleModification"
        >
            <b-tabs pills card vertical>
                <b-tab title="Stammdaten" active>
                    <PersonsEditView
                        :allowedIoFields="allowedPersonsIoFields"
                        :data="modificationRecord"
                    />
                </b-tab>
                <b-tab title="Kontaktdaten">
                    <PersonContactView :data="modificationRecord" />
                </b-tab>
                <b-tab title="Prüfungsinformationen">
                    <FormGroupBuilder
                        :fields="auditIoFields"
                        :data="modificationRecord"
                    />
                </b-tab>
                <b-tab title="Zu prüfende Teilnehmer*in" lazy>
                    <PersonsList
                        :fieldDefinition="fields"
                        :filterUpdate="participantPersonsFilterUpdate"
                        :filter="participantPersonsFilter"
                        :externalFetchMode="true"
                    ></PersonsList>
                </b-tab>
            </b-tabs>
        </EditModal>

        <ConfirmModal
            :id="modals.confirmId"
            :busy="busyExamsAuditors.mutation"
            title="Teilnehmer*in zulassen"
            @ok="handleModification"
        >
            Soll dieser Teilnehmende zugelassen werden?
        </ConfirmModal>

        <DeleteModal
            :mode="modificationMode"
            :record="modificationRecord"
            :fields="currentRecordFields"
            :selected="selected"
            :busy="busyExamsAuditors.mutation"
            @ok="handleModification"
        >
        </DeleteModal>
    </div>
</template>

<script>
import shortid from "shortid";

import ModelView from "@/mixins/ModelView/ModelView";
import Size from "@/mixins/Size/Size";
import Forms from "@/mixins/Forms/Forms";
import DateFormat from "@/mixins/Date/Date";
import Rights from "@/mixins/Rights/Rights";

import EditModal from "@/components/EditModal/EditModal";
import DeleteModal from "@/components/DeleteModal/DeleteModal";
import ConfirmModal from "@/components/ConfirmModal/ConfirmModal";
import TableExport from "@/components/TableExport/TableExport";
import TableFilter from "@/components/TableFilter/TableFilter";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import PersonsList from "@/components/PersonsList/PersonsList";
import PersonsEditView from "@/components/PersonsEditView/PersonsEditView";
import PersonContactView from "@/components/PersonContactView/PersonContactView";
import { VueEditor } from "vue2-editor";
import Spinner from "@/components/Spinner/Spinner";
import CheckboxSelect from "@/components/CheckboxSelect/CheckboxSelect";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "ExamAuditor",
    props: ["id"],
    mixins: [ModelView, Size, Forms, DateFormat, Rights],
    components: {
        EditModal,
        DeleteModal,
        TableExport,
        TableFilter,
        FormGroupBuilder,
        PersonsList,
        PersonsEditView,
        PersonContactView,
        VueEditor,
        Spinner,
        ConfirmModal,
        CheckboxSelect
    },
    data() {
        return {
            filter: {
                pattern: "",
                id: [],
                type: null
            },
            filterUpdate: false,
            disableFilterUpdate: false,
            sort: {
                by: "entry_date",
                desc: false
            },
            fields: [
                {
                    key: "__selected",
                    label: "",
                    class: "text-center",
                    static: true,
                    position: "prepend"
                },
                {
                    key: "id",
                    label: "ID",
                    sortable: true
                },
                {
                    key: "type",
                    label: "Typ",
                    sortable: true
                },
                {
                    key: "title",
                    label: "Titel",
                    sortable: false
                },
                {
                    key: "firstname",
                    label: "Vorname",
                    sortable: true
                },
                {
                    key: "lastname",
                    label: "Nachname",
                    sortable: true
                },
                {
                    key: "gender",
                    label: "Geschlecht",
                    sortable: true
                },
                {
                    key: "entry_date",
                    label: "Eintragungsdatum",
                    sortable: true
                },
                {
                    key: "place_of_operation",
                    label: "Einsatzort",
                    sortable: true
                },
                {
                    key: "presence",
                    label: "Anwesenheit",
                    sortable: true
                },
                {
                    key: "working_time",
                    label: "Arbeitszeit",
                    sortable: true
                },
                {
                    key: "__actions",
                    label: "Aktionen",
                    class: "text-right",
                    static: true,
                    position: "append"
                }
            ],
            selectedFields: [
                "id",
                "type",
                "title",
                "firstname",
                "lastname",
                "gender",
                "email",
                "entry_date",
                "place_of_operation",
                "presence",
                "working_time"
            ],
            allowedPersonsIoFields: [
                "id",
                "title",
                "firstname",
                "lastname",
                "gender"
            ],
            actions: {
                createAuditor: "examsAuditors/createData",
                createPendingParticipant: "examsPersons/createPendingData",
                edit: "persons/editData",
                delete: "examsPersons/deleteData",
                confirm: "examsPersons/confirmPendingData"
            },
            modals: {
                addNewId: "add-new-person-modal",
                confirmId: "confirm-pending-modal",
                addCambridgeImportId: "add-cambridge-modal"
            },
            existingPersonsFilter: {},
            existingPersonsFilterUpdate: false,
            auditIoFields: {
                place_of_operation: {
                    key: "place_of_operation",
                    label: "Einsatzort"
                },
                presence: {
                    key: "presence",
                    label: "Anwesenheit"
                },
                working_time: {
                    key: "working_time",
                    label: "Arbeitszeit"
                }
            },
            participantPersonsFilter: {},
            participantPersonsFilterUpdate: false,
            auditorTypes: [
                { value: "supervisor", text: "Prüfer*in" },
                { value: "reviewer", text: "Aufsicht" }
            ],
            excelImport: null
        };
    },
    computed: {
        ...mapGetters({
            busyExamsAuditors: "examsAuditors/getBusy",
            busyPersons: "persons/getBusy",
            examsAuditors: "examsAuditors/getData"
        }),
        busy() {
            return this.busyExamsAuditors.data;
        },
        busyAddingPerson() {
            return this.busyExamsAuditors.mutation || this.busyPersons.mutation;
        },
        busyExport() {
            return {
                export:
                    this.busyExamsAuditors.export || this.busyPersons.export,
                excel: this.busyExamsAuditors.excel || this.busyPersons.excel,
                pdf: this.busyExamsAuditors.pdf || this.busyPersons.pdf
            };
        },
        dataAvailable() {
            return this.examsAuditors.length > 0;
        },
        isNew() {
            return this.id == "new";
        },
        statusSelect() {
            const status = this.$store.getters["examsStatus/getSelectData"];
            status.unshift({ value: null, text: "Keine Auswahl" });
            return status;
        },
        exam() {
            const exam = this.$store.getters["exams/getDataById"](this.id);
            return exam;
        }
    },
    mounted() {
        this.fetch();
    },
    watch: {
        examsAuditors: {
            handler(val) {
                if (!this.disableFilterUpdate) {
                    this.filter.id = [
                        ...new Set(val.map(item => item.persons_id))
                    ];
                    this.filterUpdate = !this.filterUpdate;
                }
            },
            deep: true
        },
        statusSelect: {
            handler(val) {
                this.auditIoFields.status_id.options = val;
            }
        }
    },
    methods: {
        ...mapActions({
            fetchAuditors: "examsAuditors/fetchData"
        }),
        fetch() {
            this.fetchAuditors(this.id);
        },
        async exportExcel(format) {
            this.$store.dispatch("examsPersons/exportExcel", {
                id: this.id,
                format,
                filter: this.filter,
                selectedFields: this.selectedFields,
                selectedData: this._discardSelectedData(this.selected, "id")
            });
        },
        searchExistingPerson() {
            this.existingPersonsFilter = this.modificationRecord;
            this.existingPersonsFilterUpdate = !this
                .existingPersonsFilterUpdate;
        },
        useExistingPerson(data) {
            this.modificationRecord = { ...data.item };
        },
        openModal(id, recordResource = {}) {
            this.modificationMode = id;
            this.modificationRecord = recordResource;
            this.$bvModal.show(id);

            console.log(this.modificationMode);
        },
        closeModal(id) {
            this.existingPersonsFilter = {};
        },
        /** Override */
        openModificationModal({ record, mode }) {
            this.modificationMode = mode;

            var modificationRecord = { ...record };
            if (mode == "create") {
                modificationRecord = {};
            }
            this.modificationRecord = modificationRecord;

            this.$bvModal.show(this.editModalId);

            console.log("loading relations...");
        },
        async handleModification(event) {
            event.preventDefault();

            var data = { ...this.modificationRecord };

            var answer = true;

            if (this.modificationMode == this.modals.addNewId) {
                if (data.id) {
                    data = {
                        exam_id: this.id,
                        person_id: data.id,
                        type: "supervisor"
                    };
                } else {
                    var personsAction = "persons/createData";
                    if (this.checkGroup("vhs")) {
                        personsAction = "persons/createPendingData";
                    }
                    const { modification } = await this.$store.dispatch(
                        personsAction,
                        data
                    );
                    data = {
                        exam_id: this.id,
                        person_id: modification.id,
                        type: "supervisor"
                    };
                }

                console.log(data);

                var participantAction = this.actions.createAuditor;
                if (this.checkGroup("vhs")) {
                    participantAction = this.actions.createPendingParticipant;
                }
                answer = await this.$store.dispatch(participantAction, data);
            } else if (this.modificationMode == this.modals.confirmId) {
                console.log("confirm");
                answer = await this.$store.dispatch(this.actions.confirm, {
                    exam_id: this.id,
                    persons_id: data.persons_id
                });
            } else if (
                this.modificationMode == this.modals.addCambridgeImportId
            ) {
                var uploadAction = "examsPersons/importCambridge";
                if (this.checkGroup("vhs")) {
                    uploadAction = "examsPersons/importCambridgePending";
                }
                answer = await this.$store.dispatch(uploadAction, {
                    id: this.id,
                    file: this.excelImport
                });
            } else if (this.modificationMode == "delete") {
                data = {
                    exam_id: this.id,
                    person_id: data.id
                };
                answer = await this.$store.dispatch(this.actions.delete, data);
            } else {
                answer = await this.$store.dispatch(this.actions.edit, data);
                if (answer) {
                    answer = await this.$store.dispatch(
                        "examsPersons/editData",
                        data
                    );
                }
            }

            if (answer) {
                this.$bvModal.hide(this.editModalId);

                this.$bvModal.hide(this.modificationMode);

                if (this.modificationMode == "delete") {
                    this.$bvModal.hide(this.removeModalId);
                }
            }
        },
        exportCamebridgeList() {
            this.$store.dispatch("examsPersons/exportCambridge", {
                id: this.id,
                filter: this.filter,
                selectedFields: this.selectedFields,
                selectedData: this._discardSelectedData(this.selected, "id")
            });
        },
        rowClass(item, type) {
            if (item && item.pending == 1) {
                return "table-secondary";
            } else return;
        },
        applyFilter() {
            this.fetch();
        },
        resetFilter() {
            this.filter = { pattern: null, vhs: [] };
            this.currentDateSelection = "all";
            this.fetch();
        }
    }
};
</script>
