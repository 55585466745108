<template>
    <MainContainer>
        <div class="exams-detail">
            <b-row>
                <b-col cols="12">
                    <b-alert variant="warning" :show="isNew">
                        Einer Prüfung können erst Teilnehemde zugewiesen werden,
                        wenn diese erstmalig gespeichert wurde
                    </b-alert>
                    <h2>
                        <template v-if="!isNew">
                            <span
                                >Prüfung
                                <template v-if="exam">
                                    "{{ exam.title }}"
                                </template>
                                bearbeiten</span
                            >
                        </template>
                        <template v-else>
                            Neue Prüfung anlegen
                        </template>
                        <b-button
                            v-if="checkRight('write')"
                            size="md"
                            variant="primary"
                            class=" ml-3 pull-right"
                            :disabled="busy.mutation"
                            @click="handleModification"
                        >
                            <Spinner
                                v-if="busy.mutation"
                                small
                                variant="light"
                            />
                            Speichern</b-button
                        >
                        <b-button
                            v-if="!isNew"
                            size="md"
                            variant="outline-secondary"
                            class=" ml-1 pull-right"
                            :to="{ path: '/exams' }"
                        >
                            Zurück zum Hauptmenü</b-button
                        >
                    </h2>
                </b-col>
                <b-col cols="12">
                    <b-tabs content-class="mt-3">
                        <b-tab
                            title="Einstellungen"
                            :disabled="!checkRight('write')"
                        >
                            <b-tabs pills vertical>
                                <b-tab title="Allgemein">
                                    <ExamDetailSettings :id="id" />
                                </b-tab>
                                <b-tab title="Zeitraum">
                                    <ExamDateSettings :id="id" />
                                </b-tab>
                                <b-tab title="Zuweisungen">
                                    <ExamRelationSettings :id="id" />
                                </b-tab>
                            </b-tabs>
                        </b-tab>
                        <b-tab title="Teilnehmende" lazy :disabled="isNew">
                            <ExamPersons :id="id" />
                        </b-tab>
                        <b-tab title="Prüfende" lazy :disabled="isNew">
                            <ExamAuditors :id="id" />
                        </b-tab>
                        <b-tab
                            title="Controlling"
                            lazy
                            :disabled="true || !checkRight('write')"
                        >
                            <p>Funktion noch nicht fertig gestellt</p>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </div>
    </MainContainer>
</template>

<script>
import Size from "@/mixins/Size/Size";
import Rights from "@/mixins/Rights/Rights";

import MainContainer from "@/components/MainContainer/MainContainer";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import ExamDetailSettings from "@/components/ExamDetailSettings/ExamDetailSettings";
import ExamDateSettings from "@/components/ExamDateSettings/ExamDateSettings";
import ExamRelationSettings from "@/components/ExamRelationSettings/ExamRelationSettings";
import ExamPersons from "@/components/ExamPersons/ExamPersons";
import ExamAuditors from "@/components/ExamAuditors/ExamAuditors";
import EventForms from "@/components/EventForms/EventForms";
import EventContracts from "@/components/EventContracts/EventContracts";
import Spinner from "@/components/Spinner/Spinner";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "EventsDetail",
    mixins: [Size, Rights],
    components: {
        MainContainer,
        FormGroupBuilder,
        ExamDetailSettings,
        ExamRelationSettings,
        ExamDateSettings,
        ExamPersons,
        ExamAuditors,
        EventForms,
        EventContracts,
        Spinner
    },
    computed: {
        ...mapGetters({
            busy: "exams/getBusy",
            dirty: "exams/getDirty"
        }),
        id() {
            return this.$route.params.id;
        },
        isNew() {
            return this.id == "new";
        },
        exam() {
            const exam = this.$store.getters["exams/getDataById"](this.id);
            return exam;
        }
    },
    created() {
        this.fetch();
    },
    beforeMount() {
        window.addEventListener("beforeunload", this.preventNav);
        this.$once("hook:beforeDestroy", () => {
            window.removeEventListener("beforeunload", this.preventNav);
        });
    },
    beforeRouteLeave(to, from, next) {
        if (this.dirty && !this.isNew) {
            if (!window.confirm("Ohne Speichern fortfahren?")) {
                return;
            }
        }
        next();
    },
    watch: {
        event: {
            handler() {
                if (!this.dirty) {
                    this.$store.commit("exams/SET_DIRTY", true);
                }
            },
            immediate: false,
            deep: true
        }
    },
    methods: {
        ...mapActions({
            fetchDataByID: "exams/fetchDataByID"
        }),
        async fetch() {
            await this.fetchDataByID(this.id);
            this.registerRights("exams");
            this.$store.commit("exams/SET_DIRTY", false);
        },
        async handleModification() {
            if (this.isNew) {
                const newExam = await this.$store.dispatch("exams/createData", {
                    ...this.exam
                });
                this.$router.push("/exams/" + newExam.id);
            } else {
                await this.$store.dispatch("exams/editData", {
                    ...this.exam
                });
                this.$store.commit("exams/SET_DIRTY", false);
            }
        },
        preventNav(event) {
            if (!this.dirty || this.isNew) {
                return;
            }
            event.preventDefault();
            event.returnValue = "";
        }
    }
};
</script>
