<template>
    <div class="exam-detail-settings">
        <FormGroupBuilder v-if="exam" :fields="ioFields" :data="exam" />
        <hr />
        <b>Fristen</b>
        <b-table
            :fields="deadlineFields"
            :items="deadlines"
            :busy="busyDeadlines.data"
            :small="size == 'sm'"
            empty-text="Keine Daten vorhanden"
            empty-filtered-text="Für den gewählten Filter wurden keine Daten gefunden"
            bordered
            striped
            responsive
            show-empty
        >
            <template #table-busy>
                <div class="text-center text-secondary my-2">
                    <b-spinner class="mr-1 align-middle" small></b-spinner>
                    <strong>Daten werden geladen...</strong>
                </div>
            </template>

            <template #cell(deadline_date)="row">
                {{ formatDate(row.value) }}
            </template>

            <template #cell(deadline_valid)="row">
                <template v-if="row.value">
                    <font-awesome-icon icon="circle" class="text-success" />
                </template>
                <template v-else>
                    <font-awesome-icon icon="circle" class="text-danger" />
                </template>
            </template>
        </b-table>
    </div>
</template>

<script>
import Size from "@/mixins/Size/Size";
import Date from "@/mixins/Date/Date";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import CheckboxSelect from "@/components/CheckboxSelect/CheckboxSelect";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "ExamDetailSettings",
    props: ["id"],
    mixins: [Size, Date],
    components: { FormGroupBuilder, CheckboxSelect },
    data() {
        return {
            ioFields: {
                id: {
                    key: "id",
                    label: "ID",
                    disabled: true
                },
                token: {
                    key: "token",
                    label: "Kurs-/Veranstaltungsnummer",
                    disabled: false
                },
                title: {
                    key: "title",
                    label: "Titel"
                },
                type: {
                    key: "type",
                    label: "Typ",
                    type: "select",
                    options: [{ value: null, text: "Keine Auswahl" }]
                },
                candidate_nr_prefix: {
                    key: "candidate_nr_prefix",
                    label: "Candidate number prefix",
                    type: "select",
                    options: [{ value: null, text: "Keine Auswahl" }],
                    hidden: true
                }
            },
            deadlineFields: [
                { key: "id", label: "ID", sortable: true },
                {
                    key: "deadline_title",
                    label: "Fristbeschreibung",
                    sortable: true
                },
                { key: "deadline_type", label: "Fristtyp", sortable: true },
                { key: "deadline_date", label: "Fristdatum", sortable: true },
                {
                    key: "deadline_valid",
                    label: "Status",
                    class: "text-center"
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            busy: "exams/getBusy",
            busyDeadlines: "examsDeadlines/getBusy",
            deadlines: "examsDeadlines/getData"
        }),
        exam() {
            const exam = this.$store.getters["exams/getDataById"](this.id);
            return exam;
        },
        typesSelect() {
            const types = this.$store.getters["examsTypes/getSelectData"];
            types.unshift({ value: null, text: "Keine Auswahl" });
            return types;
        },
        prefixSelect() {
            const prefix = this.$store.getters[
                "examsCambridgePrefix/getSelectData"
            ];
            prefix.unshift({ value: null, text: "Keine Auswahl" });
            return prefix;
        }
    },
    created() {
        this.fetchTypes();
        this.fetchCambridgePrefix();
        this.fetchDeadlines(this.id);
    },
    watch: {
        typesSelect: {
            handler(val) {
                this.ioFields.type.options = val;
            }
        },
        prefixSelect: {
            handler(val) {
                this.ioFields.candidate_nr_prefix.options = val;
            }
        },
        "exam.type": {
            handler(val) {
                this.ioFields.candidate_nr_prefix.hidden = val != "camebridge";
            },
            immediate: true
        }
    },
    methods: {
        ...mapActions({
            fetchTypes: "examsTypes/fetchData",
            fetchCambridgePrefix: "examsCambridgePrefix/fetchData",
            fetchDeadlines: "examsDeadlines/fetchExamDeadlines"
        })
    }
};
</script>
