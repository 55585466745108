<template>
    <div class="exams-date-settings">
        <FormGroupBuilder v-if="exam" :fields="ioFields" :data="exam" />
    </div>
</template>

<script>
import Size from "@/mixins/Size/Size";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import CheckboxSelect from "@/components/CheckboxSelect/CheckboxSelect";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "ExamDateSettings",
    props: ["id"],
    mixins: [Size],
    components: { FormGroupBuilder, CheckboxSelect },
    data() {
        return {
            ioFields: {
                exam_date: {
                    key: "exam_date",
                    label: "Prüfungsdatum",
                    type: "date"
                },
                starttime: {
                    key: "starttime",
                    label: "Uhrzeit (Beginn)",
                    type: "time"
                },
                endtime: {
                    key: "endtime",
                    label: "Uhrzeit (Ende)",
                    type: "time",
                    disabled: true
                },
                date_hr: {
                    key: "date_hr",
                    type: "hr"
                },
                registration_deadline: {
                    key: "registration_deadline",
                    label: "Anmeldeschluss",
                    type: "date"
                }
            }
        };
    },
    watch: {
        "exam.exam_date": {
            handler(val) {
                this.ioFields.endtime.disabled = val ? false : true;
            },
            immediate: true
        }
    },
    computed: {
        ...mapGetters({
            busy: "exams/getBusy"
        }),
        exam() {
            const exam = this.$store.getters["exams/getDataById"](this.id);
            return exam;
        }
    },
    created() {},
    methods: {}
};
</script>
