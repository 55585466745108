<template>
    <div class="events-contracts">
        <div class="events-contracts-upload">
            <b-form-file
                :size="size"
                v-model="file"
                placeholder="Datei auswählen oder per Drag & Drop ablegen..."
                drop-placeholder="Datei hier ablegen..."
                browse-text="Durchsuchen"
            ></b-form-file>
            <b-textarea
                v-model="notice"
                placeholder="Notizen einfügen..."
                :size="size"
            ></b-textarea>
            <b-button
                class="mt-1"
                :size="size"
                :disabled="!uploadFileSelected || busyMutation"
                @click="submitFiles"
            >
                <template v-if="busyMutation">
                    <b-spinner class="mr-1 align-middle" small></b-spinner>
                </template>
                Hochladen</b-button
            >
        </div>
        <div class="file-list mt-5">
            <b-table
                :fields="fields"
                :items="files"
                :busy="busyData"
                show-empty
                small
            >
                <template #empty>
                    <div class="text-center text-secondary my-2">
                        Es wurden noch keine Dateien zu dieser Veranstaltung
                        hinterlegt
                    </div>
                </template>

                <template #table-busy>
                    <div class="text-center text-secondary my-2">
                        <b-spinner class="mr-1 align-middle" small></b-spinner>
                        <strong>Daten werden geladen...</strong>
                    </div>
                </template>

                <template #cell(modification_date)="data">
                    {{ formatDateTime(data.value) }}
                </template>

                <template #cell(__actions)="data">
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        class="mx-1"
                        @click="downloadFile(data.item.id)"
                        >Herunterladen</b-button
                    >
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        class="mx-1"
                        @click="openEditModal(data.item)"
                        >Bearbeiten</b-button
                    >
                    <b-button
                        variant="outline-danger"
                        size="sm"
                        class="mx-1"
                        @click="openDeleteModal(data.item.id)"
                        >Zuweisung entfernen</b-button
                    >
                </template>
            </b-table>
        </div>

        <EditModal
            :id="editRelationId"
            mode="custom"
            :busy="busyMutation"
            @ok="handleModification"
        >
            <b-textarea
                v-model="modificationRecord.notice"
                placeholder="Notizen einfügen..."
                :size="size"
            ></b-textarea>
        </EditModal>

        <DeleteModal
            :id="removeRelationId"
            mode="custom"
            :busy="busyMutation"
            @ok="handleModification"
        >
            Dateizuweisung wirklich löschen?
        </DeleteModal>
    </div>
</template>

<script>
import Size from "@/mixins/Size/Size";
import Date from "@/mixins/Date/Date";

import EditModal from "@/components/EditModal/EditModal";
import DeleteModal from "@/components/DeleteModal/DeleteModal";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "EventContracts",
    props: ["id"],
    mixins: [Size, Date],
    components: { EditModal, DeleteModal },
    data() {
        return {
            file: null,
            notice: null,
            fields: [
                {
                    key: "id",
                    label: "ID",
                    sortable: true
                },
                {
                    key: "orig_filename",
                    label: "Dateiname",
                    sortable: true
                },
                {
                    key: "notice",
                    label: "Bemerkung",
                    sortable: true
                },
                {
                    key: "modification_date",
                    label: "Änderungsdatum",
                    sortable: true
                },
                {
                    key: "__actions",
                    label: "Aktionen",
                    class: "text-right",
                    static: true,
                    position: "append"
                }
            ],
            modificationMode: null,
            modificationRecord: {},
            editRelationId: "edit-relation-modal",
            removeRelationId: "remove-relation-modal"
        };
    },
    computed: {
        ...mapGetters({
            busy: "events/getBusy",
            busyFiles: "files/getBusy",
            busyEventFiles: "eventsFiles/getBusy",
            eventFiles: "eventsFiles/getData"
        }),
        busyData() {
            return this.busyFiles.data || this.busyEventFiles.data;
        },
        busyMutation() {
            return this.busyFiles.mutation || this.busyEventFiles.mutation;
        },
        uploadFileSelected() {
            return this.file != null;
        },
        event() {
            const event = this.$store.getters["events/getEvent"](this.id);
            return event;
        },
        files() {
            var files = [];
            if (this.eventFiles.length > 0) {
                files = this.$store.getters["files/getData"];
                files = files.map(item => {
                    const eventFile = this.eventFiles.find(
                        ef => ef.file_id == item.id
                    );
                    if (eventFile) {
                        item.notice = eventFile.notice;
                        item.modification_date = eventFile.modification_date;
                        item.modification_author =
                            eventFile.modification_author;
                    }
                    return item;
                });
            }
            return files;
        }
    },
    watch: {
        eventFiles: {
            handler(val) {
                if (val.length > 0) {
                    this.fetchFiles({
                        filter: { id: val.map(item => item.file_id) }
                    });
                }
            },
            deep: true
        }
    },
    mounted() {
        this.fetchEventFiles(this.id);
    },
    methods: {
        ...mapActions({
            fetchEventFiles: "eventsFiles/fetchData",
            fetchFiles: "files/fetchData",
            uploadFiles: "files/createData",
            download: "files/downloadFile",
            create: "eventsFiles/createData",
            edit: "eventsFiles/editData",
            delete: "eventsFiles/deleteData"
        }),
        async submitFiles() {
            const { modification, answer } = await this.uploadFiles(this.file);
            if (answer) {
                await this.create({
                    event_id: this.id,
                    file_id: modification[0].id,
                    notice: this.notice
                });
                this.file = null;
            }
        },
        async downloadFile(id) {
            this.download({ id });
        },
        openEditModal(record) {
            this.modificationMode = this.editRelationId;
            this.modificationRecord = record;
            this.$bvModal.show(this.editRelationId);
        },
        openDeleteModal(record_id) {
            this.modificationMode = this.removeRelationId;
            this.modificationRecord = record_id;
            this.$bvModal.show(this.removeRelationId);
        },
        async handleModification(event) {
            event.preventDefault();

            var answer = true;

            if (this.modificationMode == this.removeRelationId) {
                answer = await this.delete({
                    event_id: this.id,
                    file_id: this.modificationRecord
                });
            }
            if (this.modificationMode == this.editRelationId) {
                answer = await this.edit({
                    event_id: this.id,
                    file_id: this.modificationRecord.id,
                    notice: this.modificationRecord.notice
                });
            }

            if (answer) {
                this.$bvModal.hide(this.modificationMode);
            }
        }
    }
};
</script>
