<template>
    <div class="exam-examPersons">
        <div class="general-functions">
            <b-row>
                <b-col cols="12">
                    <b-dropdown
                        v-if="!isNew"
                        text="Hinzufügen"
                        :size="size"
                        variant="outline-primary"
                    >
                        <b-dropdown-item @click="openModal(modals.addNewId)"
                            >Neue Person</b-dropdown-item
                        >
                        <b-dropdown-item
                            v-if="
                                checkRight('write') && exam.type == 'camebridge'
                            "
                            @click="openModal(modals.addCambridgeImportId)"
                            >Cambridge Import</b-dropdown-item
                        >
                    </b-dropdown>
                    <b-dropdown
                        v-if="checkRight('write')"
                        class="ml-1"
                        :size="size"
                        :disabled="busyExport.excel"
                        variant="outline-secondary"
                    >
                        <template #button-content>
                            <Spinner
                                v-if="busyExport.excel"
                                small
                                variant="secondary"
                            />
                            Listen generieren
                        </template>

                        <b-dropdown-item
                            v-if="exam.type == 'camebridge'"
                            @click="exportCamebridgeList"
                            >Cambridge-Liste</b-dropdown-item
                        >
                    </b-dropdown>
                    <TableExport
                        v-if="checkRight('write')"
                        class="ml-1 d-inline"
                        :busy="busyExport"
                        :dataAvailable="dataAvailable"
                        :showPDF="false"
                        @exportExcel="exportExcel"
                    />
                </b-col>
                <b-col md="12" class="my-4">
                    <TableFilter v-model="filter.pattern" />
                </b-col>
            </b-row>
        </div>

        <template v-if="filter.id.length > 0">
            <PersonsList
                :showedFields="selectedFields"
                :fieldDefinition="fields"
                :filter="filter"
                :filterUpdate="filterUpdate"
                :additionalData="examPersons"
                :rowClass="rowClass"
            >
                <template #cell(pending)="{data}">
                    <template v-if="data.value == 0">
                        <font-awesome-icon icon="circle" class="text-success" />
                    </template>
                    <template v-else>
                        <font-awesome-icon icon="circle" class="text-danger" />
                    </template>
                </template>

                <template #cell(preparation_center)="{data}">
                    <template v-if="data.value">
                        {{ cambridgePrepcentreName(data.value) }}
                    </template>
                </template>

                <template #actions="{data}">
                    <b-button
                        v-if="data.item.pending == 1 && checkRight('write')"
                        variant="outline-secondary"
                        size="sm"
                        class="mx-1"
                        @click="openModal(modals.confirmId, { ...data.item })"
                    >
                        Änderung zulassen
                    </b-button>
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        class="mx-1"
                        @click="
                            openModificationModal({
                                record: data.item,
                                mode: 'edit'
                            })
                        "
                        >Bearbeiten</b-button
                    >
                    <b-button
                        v-if="checkRight('delete')"
                        variant="outline-danger"
                        size="sm"
                        class="mx-1"
                        @click="openDeleteModal({ record: data.item })"
                        >Zuweisung entfernen</b-button
                    >
                </template>
            </PersonsList>
        </template>
        <template v-else>
            <div class="small text-center">
                Es wurden noch keine Teilnehmenden zugewiesen
            </div>
        </template>

        <EditModal
            :id="modals.addCambridgeImportId"
            size="lg"
            title="Personen aus Cambridge Import hinzufügen"
            :mode="modals.addCambridgeImportId"
            :busy="busyAddingPerson"
            @ok="handleModification"
            @hidden="closeModal(modals.addCambridgeImportId)"
        >
            <b>Teilnehmendenliste hochladen</b>
            <b-form-file v-model="excelImport" plain> </b-form-file>
        </EditModal>

        <EditModal
            :id="modals.addNewId"
            size="xl"
            title="Neue Person(en) hinzufügen"
            :busy="busyAddingPerson"
            @ok="handleModification"
            @hidden="closeModal(modals.addNewId)"
        >
            <b-alert show variant="warning">
                Hier können Personen zu einer Veranstaltung hinzugefügt werden.
                Über die Felder kann eine Person direkt hinzugefügt werden. Über
                den Button "Person suchen" können die eingegeben Informationen
                aber auch genutzt werden um zu überprüfen ob eine Person schon
                in den Kontakten hinterlegt ist. Durch die Auswahl dieser Person
                kann dann der vorhandene Datensatz hinzugefügt werden.
            </b-alert>
            <h5>Stammdaten</h5>
            <PersonsEditView
                :allowedIoFields="allowedPersonsIoFields"
                :busy="busyAddingPerson"
                :data="modificationRecord"
            />
            <hr />
            <h5>Kontaktdaten</h5>
            <PersonContactView
                :busy="busyAddingPerson"
                :data="modificationRecord"
            />
            <hr />
            <h5>Prüfungsinformationen</h5>
            <FormGroupBuilder
                :fields="examsIoFields"
                :data="modificationRecord"
            />
            <hr />
            <div class="d-block text-right">
                <b-button
                    :size="size"
                    :disabled="busyAddingPerson"
                    @click="searchExistingPerson"
                    >Person suchen</b-button
                >
            </div>
            <template v-if="Object.values(existingPersonsFilter).length > 0">
                <hr />
                <PersonsList
                    :selectable="false"
                    :fieldDefinition="fields"
                    :filterUpdate="existingPersonsFilterUpdate"
                    :filter="existingPersonsFilter"
                    :externalFetchMode="true"
                >
                    <template #actions="{data}">
                        <b-button
                            variant="outline-primary"
                            size="sm"
                            class="mx-1"
                            :disabled="busyAddingPerson"
                            @click="useExistingPerson(data)"
                            >Zuweisen</b-button
                        >
                    </template>
                </PersonsList>
            </template>
            <template v-if="deadlineWarning">
                <b-alert show variant="danger">
                    Die Anmeldung der Teilnehmer*in erfolgt innerhalb einer
                    Nachmeldefrist, bitte bestätigen Sie die Eingabe durch die
                    untenstehende Box
                </b-alert>
                <b-checkbox v-model="modificationRecord.confirm_deadline"
                    >Bestätigung der Anmeldung innerhalb der
                    Nachmeldefrist</b-checkbox
                >
            </template>
            <template v-if="deadlineNotPossible">
                <b-alert show variant="danger">
                    Die Anmeldung der Teilnehmer*in kann nicht mehr durchgeführt
                    werden, da die Anmeldefrist verstrichen ist.
                </b-alert>
            </template>
        </EditModal>

        <EditModal
            :busy="busyPersons.mutation"
            :mode="modificationMode"
            size="lg"
            @ok="handleModification"
        >
            <b-tabs pills card vertical>
                <b-tab title="Stammdaten" active>
                    <PersonsEditView
                        :allowedIoFields="allowedPersonsIoFields"
                        :data="modificationRecord"
                    />
                </b-tab>
                <b-tab title="Kontaktdaten">
                    <PersonContactView :data="modificationRecord" />
                </b-tab>
                <b-tab title="Prüfungsinformationen">
                    <FormGroupBuilder
                        :fields="examsIoFields"
                        :data="modificationRecord"
                    />
                </b-tab>
            </b-tabs>
        </EditModal>

        <ConfirmModal
            :id="modals.confirmId"
            :busy="busyExamPersons.mutation"
            title="Teilnehmer*in zulassen"
            @ok="handleModification"
        >
            Soll dieser Teilnehmende zugelassen werden?
        </ConfirmModal>

        <DeleteModal
            :mode="modificationMode"
            :record="modificationRecord"
            :fields="currentRecordFields"
            :selected="selected"
            :busy="busyExamPersons.mutation"
            @ok="handleModification"
        >
        </DeleteModal>
    </div>
</template>

<script>
import shortid from "shortid";

import ModelView from "@/mixins/ModelView/ModelView";
import Size from "@/mixins/Size/Size";
import Forms from "@/mixins/Forms/Forms";
import DateFormat from "@/mixins/Date/Date";
import Rights from "@/mixins/Rights/Rights";

import EditModal from "@/components/EditModal/EditModal";
import DeleteModal from "@/components/DeleteModal/DeleteModal";
import ConfirmModal from "@/components/ConfirmModal/ConfirmModal";
import TableExport from "@/components/TableExport/TableExport";
import TableFilter from "@/components/TableFilter/TableFilter";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import PersonsList from "@/components/PersonsList/PersonsList";
import PersonsEditView from "@/components/PersonsEditView/PersonsEditView";
import PersonContactView from "@/components/PersonContactView/PersonContactView";
import { VueEditor } from "vue2-editor";
import Spinner from "@/components/Spinner/Spinner";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "ExamParticipants",
    props: ["id"],
    mixins: [ModelView, Size, Forms, DateFormat, Rights],
    components: {
        EditModal,
        DeleteModal,
        TableExport,
        TableFilter,
        FormGroupBuilder,
        PersonsList,
        PersonsEditView,
        PersonContactView,
        VueEditor,
        Spinner,
        ConfirmModal
    },
    data() {
        return {
            filter: {
                pattern: "",
                id: [],
                pending: "all"
            },
            filterUpdate: false,
            disableFilterUpdate: false,
            sort: {
                by: "entry_date",
                desc: false
            },
            fields: [
                {
                    key: "__selected",
                    label: "",
                    class: "text-center",
                    static: true,
                    position: "prepend"
                },
                {
                    key: "id",
                    label: "ID",
                    sortable: true
                },
                {
                    key: "pending",
                    label: "Ausstehend",
                    sortable: true
                },
                {
                    key: "title",
                    label: "Titel",
                    sortable: false
                },
                {
                    key: "firstname",
                    label: "Vorname",
                    sortable: true
                },
                {
                    key: "lastname",
                    label: "Nachname",
                    sortable: true
                },
                {
                    key: "gender",
                    label: "Geschlecht",
                    sortable: true
                },
                {
                    key: "birthday",
                    label: "Geburtstag",
                    sortable: true
                },
                {
                    key: "birth_place",
                    label: "Geburtsort",
                    sortable: true
                },
                {
                    key: "origin_country",
                    label: "Herkunftsland",
                    sortable: true
                },
                {
                    key: "status_id",
                    label: "Status",
                    sortable: true
                },
                {
                    key: "entry_date",
                    label: "Eintragungsdatum",
                    sortable: true
                },
                {
                    key: "candidate_type",
                    label: "Kandidatentyp",
                    sortable: true
                },
                {
                    key: "preparation_center",
                    label: "Schule",
                    sortable: true
                },
                {
                    key: "packing_code",
                    label: "Packing Code",
                    sortable: true
                },
                {
                    key: "campaign_code",
                    label: "Campaign Code",
                    sortable: true
                },
                {
                    key: "__actions",
                    label: "Aktionen",
                    class: "text-right",
                    static: true,
                    position: "append"
                }
            ],
            selectedFields: [
                "id",
                "pending",
                "title",
                "firstname",
                "lastname",
                "gender",
                "email",
                "birthday",
                "birth_place",
                "origin_country",
                "entry_date",
                "candidate_type",
                "preparation_center",
                "packing_code",
                "campaign_code",
                "status_id"
            ],
            allowedPersonsIoFields: [
                "id",
                "title",
                "firstname",
                "lastname",
                "gender",
                "birthday",
                "birth_place",
                "origin_country"
            ],
            actions: {
                createParticipant: "examsPersons/createData",
                createPendingParticipant: "examsPersons/createPendingData",
                edit: "persons/editData",
                delete: "examsPersons/deleteData",
                confirm: "examsPersons/confirmPendingData"
            },
            modals: {
                addNewId: "add-new-person-modal",
                confirmId: "confirm-pending-modal",
                addCambridgeImportId: "add-cambridge-modal"
            },
            existingPersonsFilter: {},
            existingPersonsFilterUpdate: false,
            examsIoFields: {
                status_id: {
                    key: "status_id",
                    label: "Status",
                    type: "select",
                    options: [{ value: null, text: "Keine Auswahl" }]
                },
                candidate_type: {
                    key: "candidate_type",
                    label: "Kandidatentyp",
                    type: "select",
                    options: [
                        { value: null, text: "Bitte wählen..." },
                        { value: "internal", text: "Intern" },
                        { value: "external", text: "Extern" }
                    ]
                },
                preparation_center: {
                    key: "preparation_center",
                    label: "Schule",
                    disabled: true,
                    type: "select",
                    options: [{ value: null, text: "Keine Auswahl..." }]
                },
                packing_code: {
                    key: "packing_code",
                    label: "Packing Code"
                },
                campaign_code: {
                    key: "campaign_code",
                    label: "Campaign Code"
                }
            },
            excelImport: null,
            deadlineWarning: false,
            deadlineNotPossible: false
        };
    },
    computed: {
        ...mapGetters({
            busyExamPersons: "examsPersons/getBusy",
            busyPersons: "persons/getBusy",
            examPersons: "examsPersons/getData",
            cambridgePrepcentres: "examsCambridgePrepcentre/getSelectData",
            cambridgePrepcentreName: "examsCambridgePrepcentre/getDataName"
        }),
        busy() {
            return this.busyExamPersons.data;
        },
        busyAddingPerson() {
            return this.busyExamPersons.mutation || this.busyPersons.mutation;
        },
        busyExport() {
            return {
                export: this.busyExamPersons.export || this.busyPersons.export,
                excel: this.busyExamPersons.excel || this.busyPersons.excel,
                pdf: this.busyExamPersons.pdf || this.busyPersons.pdf
            };
        },
        dataAvailable() {
            return this.examPersons.length > 0;
        },
        isNew() {
            return this.id == "new";
        },
        statusSelect() {
            const status = this.$store.getters["examsStatus/getSelectData"];
            status.unshift({ value: null, text: "Keine Auswahl" });
            return status;
        },
        exam() {
            const exam = this.$store.getters["exams/getDataById"](this.id);
            return exam;
        }
    },
    mounted() {
        this.fetch();
        this.fetchExamsStatus();
    },
    watch: {
        examPersons: {
            handler(val) {
                if (!this.disableFilterUpdate) {
                    this.filter.id = [
                        ...new Set(val.map(item => item.persons_id))
                    ];
                    this.filterUpdate = !this.filterUpdate;
                }
            },
            deep: true
        },
        statusSelect: {
            handler(val) {
                this.examsIoFields.status_id.options = val;
            }
        },
        "modificationRecord.candidate_type": {
            handler(val) {
                this.examsIoFields.preparation_center.disabled =
                    val != "external";
            }
        },
        cambridgePrepcentres: {
            handler(val) {
                this.examsIoFields.preparation_center.options = val;
                this.examsIoFields.preparation_center.options.unshift({
                    value: null,
                    text: "Keine Auswahl..."
                });
            }
        }
    },
    methods: {
        ...mapActions({
            fetchParticipants: "examsPersons/fetchData",
            fetchExamsStatus: "examsStatus/fetchData"
        }),
        async fetch() {
            await this.fetchParticipants({ id: this.id, filter: this.filter });
            this.registerRights("exams-persons");
        },
        async fetchCambridgePrepcentres() {
            const exam = this.exam;
            if (exam) {
                await this.$store.dispatch(
                    "examsCambridgePrepcentre/fetchData",
                    {
                        filter: {
                            _cambridge_rel_sub_prep: exam.locations
                        }
                    }
                );
            }
        },
        async exportExcel(format) {
            this.$store.dispatch("examsPersons/exportExcel", {
                id: this.id,
                format,
                filter: this.filter,
                selectedFields: this.selectedFields,
                selectedData: this._discardSelectedData(this.selected, "id")
            });
        },
        searchExistingPerson() {
            this.existingPersonsFilter = this.modificationRecord;
            this.existingPersonsFilterUpdate = !this
                .existingPersonsFilterUpdate;
        },
        useExistingPerson(data) {
            this.modificationRecord = { ...data.item };
        },
        openModal(id, recordResource = {}) {
            this.fetchCambridgePrepcentres();
            this.modificationMode = id;
            this.modificationRecord = recordResource;
            this.$bvModal.show(id);
        },
        openModificationModal({ record, mode }) {
            this.fetchCambridgePrepcentres();

            this.modificationMode = mode;

            var modificationRecord = { ...record };
            if (mode == "create") {
                modificationRecord = {};
            }
            this.modificationRecord = modificationRecord;

            this.$bvModal.show(this.editModalId);
        },
        closeModal(id) {
            this.existingPersonsFilter = {};
        },
        /** Override */
        async handleModification(event) {
            event.preventDefault();

            var data = { ...this.modificationRecord };

            var answer = true;

            if (this.modificationMode == this.modals.addNewId) {
                if (data.id) {
                    data = {
                        exam_id: this.id,
                        person_id: data.id,
                        type: "participant"
                    };
                } else {
                    var personsAction = "persons/createData";
                    if (this.checkGroup("vhs")) {
                        personsAction = "persons/createPendingData";
                    }
                    const { modification } = await this.$store.dispatch(
                        personsAction,
                        data
                    );
                    data = {
                        exam_id: this.id,
                        person_id: modification.id,
                        type: "participant",
                        confirm_deadline: data.confirm_deadline
                    };
                }

                var participantAction = this.actions.createParticipant;
                if (this.checkGroup("vhs")) {
                    participantAction = this.actions.createPendingParticipant;
                }
                const response = await this.$store.dispatch(
                    participantAction,
                    data
                );
                answer = response.ok;
                if (!response.ok) {
                    if (response.answer.statusCode == 409) {
                        this.deadlineWarning = true;
                    } else if (response.answer.statusCode == 406) {
                        this.deadlineNotPossible = true;
                    }
                }
            } else if (this.modificationMode == this.modals.confirmId) {
                console.log("confirm");
                answer = await this.$store.dispatch(this.actions.confirm, {
                    exam_id: this.id,
                    persons_id: data.persons_id
                });
            } else if (
                this.modificationMode == this.modals.addCambridgeImportId
            ) {
                var uploadAction = "examsPersons/importCambridge";
                if (this.checkGroup("vhs")) {
                    uploadAction = "examsPersons/importCambridgePending";
                }
                answer = await this.$store.dispatch(uploadAction, {
                    id: this.id,
                    file: this.excelImport
                });
            } else if (this.modificationMode == "delete") {
                data = {
                    exam_id: this.id,
                    person_id: data.id
                };
                answer = await this.$store.dispatch(this.actions.delete, data);
            } else {
                answer = await this.$store.dispatch(this.actions.edit, data);
                if (answer) {
                    answer = await this.$store.dispatch(
                        "examsPersons/editData",
                        data
                    );
                }
            }

            if (answer) {
                this.$bvModal.hide(this.editModalId);

                this.$bvModal.hide(this.modificationMode);

                if (this.modificationMode == "delete") {
                    this.$bvModal.hide(this.removeModalId);
                }

                this.deadlineWarning = false;
                this.deadlineNotPossible = false;
            }
        },
        exportCamebridgeList() {
            this.$store.dispatch("examsPersons/exportCambridge", {
                id: this.id,
                filter: this.filter,
                selectedFields: this.selectedFields,
                selectedData: this._discardSelectedData(this.selected, "id")
            });
        },
        rowClass(item, type) {
            if (item && item.pending == 1) {
                return "table-secondary";
            } else return;
        }
    }
};
</script>
