<template>
    <div class="event-forms">
        <div class="general-functions mb-4">
            <b-row>
                <b-col cols="12">
                    <b-button
                        :disabled="true"
                        v-if="!isNew"
                        :size="size"
                        variant="outline-primary"
                        @click="openModal(addFormId)"
                    >
                        Formular erstellen
                    </b-button>
                    <b-button
                        v-if="!isNew"
                        :size="size"
                        class="ml-1"
                        variant="outline-primary"
                        @click="openModal(relateFormId)"
                        >Formular(e) zuweisen</b-button
                    >
                </b-col>
            </b-row>
        </div>

        <template v-if="relatedForms.length > 0">
            <FormsList
                :filter="{ id: relatedForms }"
                :filterUpdate="filterUpdate"
            >
                <template #actions="{data}">
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        class="mx-1"
                        :to="{ path: `/forms/${data.item.id}` }"
                        >Formular bearbeiten</b-button
                    >
                    <b-button
                        variant="outline-danger"
                        size="sm"
                        class="mx-1"
                        @click="openDeleteModal(data.item.id)"
                        >Zuweisung löschen</b-button
                    >
                </template>
            </FormsList></template
        >
        <template v-else>
            <div class="small text-center">
                Es wurden noch keine Formulare zugewiesen
            </div>
        </template>

        <EditModal
            :id="addFormId"
            :busy="false"
            mode="custom"
            title="Formular erstellen"
            @ok="handleModification(addFormId)"
        >
            <b>Neues Formular erstellen</b>
            <FormGroupBuilder
                :fields="newFormIoFields"
                :data="modificationRecord"
            />
            <hr class="mt-5" />
            <p class="text-danger font-weight-bold text-center">oder</p>
            <hr class="mb-4" />
            <b>Vorhandenes Formular duplizieren</b>
            <FormGroupBuilder
                :fields="duplicateFormIoFields"
                :data="modificationRecord"
            />
        </EditModal>

        <EditModal
            :id="relateFormId"
            :busy="busy.mutation"
            mode="custom"
            title="Formluar(e) zuweisen"
            @ok="handleModification"
        >
            <p>Zugewiesene Formulare auswählen:</p>
            <CheckboxSelect
                :options="formsSelect"
                v-model="modificationRecord.form_id"
            />
        </EditModal>

        <DeleteModal
            :id="removeRelationId"
            mode="custom"
            :busy="busy.mutation"
            @ok="handleModification"
        >
            Formularzuweisung wirklich löschen?
        </DeleteModal>
    </div>
</template>

<script>
import ApiFactory from "@/api/ApiFactory";

import Size from "@/mixins/Size/Size";

import EditModal from "@/components/EditModal/EditModal";
import DeleteModal from "@/components/DeleteModal/DeleteModal";
import TableFilter from "@/components/TableFilter/TableFilter";
import FormsList from "@/components/FormsList/FormsList";
import CheckboxSelect from "@/components/CheckboxSelect/CheckboxSelect";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";

import { mapActions, mapGetters } from "vuex";

const FormsApi = ApiFactory.get("forms");

export default {
    name: "EventForms",
    props: ["id"],
    mixins: [Size],
    components: {
        EditModal,
        DeleteModal,
        TableFilter,
        FormsList,
        CheckboxSelect,
        FormGroupBuilder
    },
    data() {
        return {
            filterUpdate: false,
            formsSelect: [],
            modificationMode: null,
            modificationRecord: {},
            addFormId: "add-form-modal",
            relateFormId: "relate-form-modal",
            removeRelationId: "remove-relation-modal",
            actions: {
                create: "eventsForms/createData",
                delete: "eventsForms/deleteData"
            },
            newFormIoFields: {
                title: {
                    key: "title",
                    label: "Titel"
                }
            },
            duplicateFormIoFields: {
                form_id: {
                    key: "form_id",
                    label: "Zu duplizierendes Formular",
                    type: "select",
                    options: []
                }
            }
        };
    },
    computed: {
        ...mapGetters({
            relatedForms: "eventsForms/getData",
            busy: "eventsForms/getBusy"
        }),
        isNew() {
            return this.id == "new";
        }
    },
    watch: {
        relatedForms: {
            handler(val, oldVal) {
                this.filterUpdate = !this.filterUpdate;
            },
            deep: true
        }
    },
    async mounted() {
        await this.fetchForms(this.id);
    },
    methods: {
        ...mapActions({
            fetchForms: "eventsForms/fetchData"
        }),
        async fetchFormsExternal() {
            const response = await FormsApi.get();
            if (response.ok) {
                const selectData = response.json.data
                    .filter(item => !this.relatedForms.includes(item.id))
                    .map(item => {
                        return {
                            value: item.id,
                            text: item.title + " (" + item.id + ")"
                        };
                    });
                this.formsSelect = selectData;
                this.duplicateFormIoFields.form_id.options = selectData;
            }
        },
        openModal(id) {
            if (id == this.relateFormId || id == this.addFormId) {
                this.fetchFormsExternal();
            }
            this.modificationMode = id;
            this.$bvModal.show(id);
        },
        openDeleteModal(record_id) {
            this.modificationMode = this.removeRelationId;
            this.modificationRecord = record_id;
            this.$bvModal.show(this.removeRelationId);
        },

        /** Override */
        async handleModification(event) {
            event.preventDefault();

            const data = { ...this.modificationRecord };

            var answer = true;

            if (this.modificationMode == this.relateFormId) {
                for (const form_id of data.form_id) {
                    const response = await this.$store.dispatch(
                        this.actions.create,
                        {
                            event_id: this.id,
                            form_id: form_id
                        }
                    );
                    if (!response) {
                        answer = false;
                    }
                }
            } else if (this.modificationMode == this.removeRelationId) {
                console.log(data);
                answer = await this.$store.dispatch(this.actions.delete, {
                    event_id: this.id,
                    form_id: this.modificationRecord
                });
            }

            if (answer) {
                this.$bvModal.hide(this.editModalId);
                this.$bvModal.hide(this.modificationMode);
            }
        }
    }
};
</script>
