<template>
    <div class="forms">
        <b-table
            :items="forms"
            :fields="currentSelectedFields"
            :filter="filter.pattern"
            :busy="busy.data"
            :small="size == 'sm'"
            select-mode="multi"
            sort-by="lastname"
            ref="selectableTable"
            empty-text="Keine Daten vorhanden"
            empty-filtered-text="Für den gewählten Filter wurden keine Daten gefunden"
            foot-clone
            no-footer-sorting
            selectable
            bordered
            striped
            responsive
            show-empty
            @row-selected="onRowSelected"
        >
            <template #thead-top="{ columns }">
                <b-tr>
                    <b-td>
                        <p class="small m-0">Gesamt: {{ forms.length }}</p>
                        <p class="small m-0">
                            Gewählt:
                            <template v-if="selected.length > 0"
                                >{{ selected.length }}
                            </template>
                            <template v-else>-</template>
                        </p>
                    </b-td>
                    <b-td :colspan="currentRecordFields.length"></b-td>
                    <b-td class="text-right"
                        ><b-dropdown
                            variant="outline-secondary"
                            text="Spaltenauswahl"
                            :size="size"
                        >
                            <b-dropdown-form>
                                <b-form-checkbox-group
                                    v-model="selectedFields"
                                    :options="recordFields"
                                    value-field="key"
                                    text-field="label"
                                ></b-form-checkbox-group>
                            </b-dropdown-form>
                        </b-dropdown>
                    </b-td>
                </b-tr>
            </template>

            <template #table-busy>
                <div class="text-center text-secondary my-2">
                    <b-spinner class="mr-1 align-middle" small></b-spinner>
                    <strong>Daten werden geladen...</strong>
                </div>
            </template>

            <template #head(__selected)>
                <input
                    type="checkbox"
                    v-model="selectAll"
                    :true-value="true"
                    :false-value="false"
                    @change="toggleAllSelectedRows"
                />
            </template>

            <template #cell(__selected)="{ rowSelected }">
                <input
                    type="checkbox"
                    :checked="rowSelected"
                    :true-value="true"
                    :false-value="false"
                    disabled
                />
            </template>

            <template #cell(is_active)="data">
                <template v-if="data.value == 1">
                    <font-awesome-icon icon="check" class="text-success" />
                </template>
                <template v-else>
                    <font-awesome-icon icon="times" class="text-danger" />
                </template>
            </template>

            <template #cell(disable_by_count)="data">
                <template v-if="data.value == 1">
                    <font-awesome-icon icon="check" class="text-success" />
                    ({{ data.item.disable_count }})
                </template>
                <template v-else>
                    <i class="text-muted small">Keine Beschränkung</i>
                </template>
            </template>

            <template #cell(disable_by_date)="data">
                <template v-if="data.value == 1">
                    <font-awesome-icon icon="check" class="text-success" />
                    ({{ formatDate(data.item.disable_date) }})
                </template>
                <template v-else>
                    <i class="text-muted small">Keine Beschränkung</i>
                </template>
            </template>

            <template #cell(actual_active)="data">
                <template v-if="data.value">
                    <font-awesome-icon icon="circle" class="text-success" />
                </template>
                <template v-else>
                    <font-awesome-icon icon="circle" class="text-danger" />
                </template>
            </template>

            <template #cell(tags)="data">
                <b-badge
                    v-for="tag_id in data.value"
                    :key="tag_id"
                    class="mx-1"
                >
                    {{ tagName(tag_id) }}
                </b-badge>
            </template>

            <template #cell(form_url)="data">
                <b-button
                    :size="size"
                    :href="data.value"
                    variant="outline-secondary"
                    target="_blank"
                    >Formular öffnen</b-button
                >
            </template>

            <template #cell(__actions)="data">
                <slot name="actions" v-bind:data="data">
                    <b-button
                        variant="outline-info"
                        size="sm"
                        class="mx-1"
                        @click="openConfirmDuplicateModal(data.item.id)"
                        >Duplizieren</b-button
                    >
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        class="mx-1"
                        :to="{ path: `/forms/${data.item.id}` }"
                        >Bearbeiten</b-button
                    >
                    <b-button
                        variant="outline-danger"
                        size="sm"
                        class="mx-1"
                        @click="openDeleteModal({ record: data.item })"
                        >Löschen</b-button
                    >
                </slot>
            </template>
        </b-table>
    </div>
</template>

<script>
import ModelView from "@/mixins/ModelView/ModelView";
import Size from "@/mixins/Size/Size";
import DateFormat from "@/mixins/Date/Date";

import MainContainer from "@/components/MainContainer/MainContainer";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import TableExport from "@/components/TableExport/TableExport";
import TableFilter from "@/components/TableFilter/TableFilter";
import ConfirmModal from "@/components/ConfirmModal/ConfirmModal";
import DeleteModal from "@/components/DeleteModal/DeleteModal";
import CheckboxSelect from "@/components/CheckboxSelect/CheckboxSelect";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "FormsList",
    props: {
        filter: {
            type: Object,
            default: () => {
                return {
                    pattern: null,
                    tags: []
                };
            }
        },
        filterUpdate: {
            type: Boolean,
            default: false
        }
    },
    components: {
        MainContainer,
        FormGroupBuilder,
        TableExport,
        TableFilter,
        ConfirmModal,
        DeleteModal,
        CheckboxSelect
    },
    mixins: [ModelView, Size, DateFormat],
    data() {
        return {
            fields: [
                {
                    key: "__selected",
                    label: "",
                    class: "text-center",
                    static: true,
                    position: "prepend"
                },
                {
                    key: "id",
                    label: "ID",
                    sortable: true
                },
                {
                    key: "title",
                    label: "Titel",
                    sortable: true
                },
                {
                    key: "is_active",
                    label: "aktiv",
                    class: "text-center"
                },
                {
                    key: "disable_by_count",
                    label: "Teilnahmebeschränkung",
                    class: "text-center"
                },
                {
                    key: "disable_by_date",
                    label: "Zeitliche Beschränkung",
                    class: "text-center"
                },
                {
                    key: "actual_active",
                    label: "Status",
                    class: "text-center"
                },
                {
                    key: "tags",
                    label: "Tags"
                },
                {
                    key: "form_url",
                    label: "URL"
                },
                {
                    key: "__actions",
                    label: "Aktionen",
                    class: "text-right",
                    static: true,
                    position: "append"
                }
            ],
            selectedFields: [
                "id",
                "title",
                "is_active",
                "disable_by_count",
                "disable_by_date",
                "actual_active",
                "tags",
                "form_url"
            ]
        };
    },
    computed: {
        ...mapGetters({
            busy: "forms/getBusy",
            forms: "forms/getForms",
            formName: "forms/getFormName",
            tagName: "formsTags/getTagName",
            tagsSelect: "formsTags/getTagsForSelect"
        }),
        dataAvailable() {
            return this.forms.length > 0;
        }
    },
    created() {
        this.fetch({ filter: this.filter });
        this.fetchTags();
    },
    watch: {
        filterUpdate: {
            handler(val) {
                this.applyFilter();
            }
        }
    },
    methods: {
        ...mapActions({
            fetch: "forms/fetchForms",
            fetchTags: "formsTags/fetchTags"
        }),
        applyFilter() {
            this.fetch({ filter: this.filter });
        }
    }
};
</script>
