export default {
    data() {
        return {
            rights: {}
        };
    },
    methods: {
        registerRights(key) {
            this.rights = this.$store.getters["rights/getRightsFor"](key);
        },
        checkRight(right = "read") {
            if (this.rights[right]) {
                return this.rights[right];
            }
            return false;
        },
        checkGroup(groupName) {
            const auth = this.$store.getters["auth/getAuth"];
            return auth.groupName == groupName;
        }
    }
};
