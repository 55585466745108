<template>
    <div class="exams-relation-settings">
        <div class="exams-relation-location">
            <strong>Standort</strong>
            <CheckboxSelect
                v-if="exam"
                :size="size"
                :options="locationsSelect"
                v-model="exam.locations"
            />
        </div>
    </div>
</template>

<script>
import Size from "@/mixins/Size/Size";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import CheckboxSelect from "@/components/CheckboxSelect/CheckboxSelect";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "ExamRelationSettings",
    props: ["id"],
    mixins: [Size],
    components: { FormGroupBuilder, CheckboxSelect },
    data() {
        return {};
    },
    watch: {},
    computed: {
        ...mapGetters({
            busy: "exams/getBusy",
            locationsSelect: "examsCambridgeSubcentre/getSelectData"
        }),
        exam() {
            const exam = this.$store.getters["exams/getDataById"](this.id);
            return exam;
        }
    },
    created() {
        this.fetchLocations({ filter: null });
    },
    methods: {
        ...mapActions({
            fetchLocations: "examsCambridgeSubcentre/fetchData"
        })
    }
};
</script>
